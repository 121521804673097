<template>
  <v-btn
    v-bind="$props"
    tag="a"
    :class="isIcon ? 'hig-icon' : 'hig-button'"
    tabindex="0"
    v-on="$listeners"
  >
    <slot></slot>
  </v-btn>
</template>

<script>
import { VBtn } from "vuetify/lib";
// disable ripple directive
VBtn.options.props.ripple.default = false;

export default {
  name: "HigBtn",
  data() {
    return {
      isIcon: this.$props.icon,
    };
  },
  props: {
    // the props of vuetify component is under options attr
    ...VBtn.options.props,
  },
};
</script>

// if use scoped, then our own style can not be worked
<style lang="scss">
.hig-icon {
  width: 32px !important;
  height: 32px !important;
  opacity: 0.7;
  border-radius: 2px;
  &:hover {
    &:before {
      opacity: 0 !important;
    }
    opacity: 1;
  }
  &:focus {
    box-shadow: 0 0 0 2px rgba(56, 171, 223, 0.35) !important;
    &:before {
      opacity: 0 !important;
    }
  }
  &:active {
    background-color: rgba(209, 222, 238, 0.2) !important;
  }
}
.hig-button {
  border-radius: 2px;
  min-width: 110px !important;
  color: #fff !important;
  box-shadow: none !important;
  &.v-btn--text {
    color: #6dd2ff !important;
  }
  &.v-btn--outlined {
    border: thin solid rgba(209, 222, 238, 0.5);
  }
  &:hover {
    box-shadow: 0 0 0 2px rgba(128, 128, 128, 0.15) !important;
    &:before {
      opacity: 0 !important;
    }
  }
  &:focus {
    box-shadow: 0 0 0 2px rgba(6, 150, 215, 0.35) !important;
    &:before {
      opacity: 0 !important;
    }
  }
  &:active {
    box-shadow: 0 0 0 4px rgba(128, 128, 128, 0.25) !important;
  }
}
</style>
