import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";

const Feedback = () => import("components/FeedbackPage.vue");
const ThankYou = () => import("components/ThankYou.vue");
const WhatHappensRoot = () => import("../views/WhatHappensRoot.vue");
const UnSubscribe = () => import("components/UnSubscribe.vue");
const Success = () => import("components/status/SuccessPage.vue");
const ServerError = () => import("components/status/ServerError.vue");

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "Home",
  },
  {
    path: "/feedback",
    name: "feedback",
    component: Feedback,
    props: (route) => ({
      token: route.query.token,
      locale: route.query.locale,
      reportId: route.query.report_id,
    }),
  },
  {
    path: "/thank_you",
    name: "thank_you",
    component: ThankYou,
  },
  {
    path: "/whatHappens.jsp",
    name: "what_happens",
    component: WhatHappensRoot,
    props: (route) => ({
      language: route.query.language,
      version: route.query.version,
    }),
    meta: {
      title: "What Happens",
    },
  },
  {
    path: "/ErrorReportServer/Help",
    redirect: (route) => ({
      name: "what_happens",
      query: { language: route.query.userlcid },
    }),
  },
  {
    path: "/unsubscribe",
    name: "unsubscribe",
    component: UnSubscribe,
    props: (route) => ({
      locale: route.query.locale,
      token: route.query.token,
      email: route.query.email,
    }),
  },
  {
    path: "/email_updated",
    name: "email_updated",
    component: Success,
    props: {
      fromEmail: true,
    },
  },
  {
    path: "/subscription_updated",
    name: "subscription_updated",
    component: Success,
    props: {
      fromEmail: false,
    },
  },
  {
    path: "/server_error",
    name: "server_error",
    component: ServerError,
  },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AboutPage.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, fom, next) => {
  window.document.title =
    to.meta?.title == undefined ? "Response Page" : to.meta?.title;
  next();
});

export default router;
